define("global-admin/security/cloud-credentials/index/controller", ["exports", "global-admin/security/cloud-credentials/index/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEADERS = [{
    name: 'name',
    sort: ['name'],
    searchField: 'name',
    translationKey: 'generic.name',
    type: 'string'
  }, {
    classNames: 'text-right pr-20',
    name: 'created',
    sort: ['created'],
    searchField: false,
    translationKey: 'generic.created'
  }];

  var _default = Ember.Controller.extend({
    modal: Ember.inject.service(),
    layout: _template.default,
    sortBy: 'name',
    searchText: '',
    extraSearchFields: ['displayType'],
    headers: HEADERS,
    actions: {
      addCloudKey: function addCloudKey() {
        this.modal.toggleModal('modal-add-cloud-credential', {
          mode: 'new'
        });
      }
    },
    filteredContent: Ember.computed('model.@each.{id}', function () {
      return Ember.get(this, 'model').sortBy('id');
    })
  });

  _exports.default = _default;
});