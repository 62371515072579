define("shared/components/form-versions/component", ["exports", "shared/components/form-versions/template", "shared/utils/constants", "shared/utils/parse-version", "shared/utils/sort", "semver"], function (_exports, _template, _constants, _parseVersion, _sort, _semver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    cluster: null,
    versionChoices: null,
    versions: null,
    initialVersion: null,
    disabled: false,
    value: null,
    mode: 'new',
    showNotAllowed: false,
    editing: Ember.computed.equal('mode', 'edit'),
    isView: Ember.computed.equal('mode', 'view'),
    defaultK8sVersionRange: Ember.computed.alias("settings.".concat(_constants.default.SETTING.VERSION_SYSTEM_K8S_DEFAULT_RANGE)),
    supportedVersionsRange: Ember.computed.alias("settings.".concat(_constants.default.SETTING.VERSION_K8S_SUPPORTED_RANGE)),
    defaultK8sVersion: Ember.computed.alias("settings.".concat(_constants.default.SETTING.VERSION_RKE_K8S_DEFAULT)),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', function () {
        _this.initVersions();
      });
    },
    isRke: Ember.computed('cluster', function () {
      var cluster = this.cluster;

      if (Ember.get(cluster, 'rancherKubernetesEngineConfig')) {
        return true;
      }

      return false;
    }),
    initVersions: function initVersions() {
      var _this2 = this;

      var defaultK8sVersionRange = this.defaultK8sVersionRange,
          versions = this.versions,
          supportedVersionsRange = this.supportedVersionsRange,
          editing = this.editing,
          initialVersion = this.initialVersion,
          defaultK8sVersion = this.defaultK8sVersion,
          showNotAllowed = this.showNotAllowed,
          _this$applyClusterTem = this.applyClusterTemplate,
          applyClusterTemplate = _this$applyClusterTem === void 0 ? false : _this$applyClusterTem,
          _this$clusterTemplate = this.clusterTemplateCreate,
          clusterTemplateCreate = _this$clusterTemplate === void 0 ? false : _this$clusterTemplate,
          _this$clusterTemplate2 = this.clusterTemplateQuestions,
          clusterTemplateQuestions = _this$clusterTemplate2 === void 0 ? [] : _this$clusterTemplate2;
      var out = versions;
      var overrideMatch = null;

      if (initialVersion) {
        if (!out.includes(initialVersion) && editing) {
          out.unshift(initialVersion);
        }
      } else {
        initialVersion = defaultK8sVersion;
      }

      var initialWithoutX = initialVersion.endsWith('.x') ? initialVersion.replace(/x$/, '0') : initialVersion;
      var maxVersion = (0, _parseVersion.maxSatisfying)(versions, defaultK8sVersionRange);

      if (applyClusterTemplate) {
        overrideMatch = (clusterTemplateQuestions || []).findBy('variable', 'rancherKubernetesEngineConfig.kubernetesVersion');

        if (overrideMatch) {
          if (Ember.isEmpty(overrideMatch.satisfies) && initialVersion.endsWith('.x')) {
            // the template creator lets them override this but the initial version is a dot x so we should choose the biggest version in the .x range
            maxVersion = (0, _parseVersion.maxSatisfying)(versions, initialVersion);
          } else {
            if (overrideMatch.satisfies) {
              supportedVersionsRange = overrideMatch.satisfies;
            }

            maxVersion = (0, _parseVersion.maxSatisfying)(versions, supportedVersionsRange);
          }
        }
      } // if we're not consuming or creating a cluster template we should use the default translation for the label


      if (!applyClusterTemplate && !clusterTemplateCreate) {
        showNotAllowed = false;
      }

      out = [].concat(_toConsumableArray((0, _sort.sortVersions)(out.filter(function (v) {
        return v.endsWith('.x');
      })).reverse()), _toConsumableArray((0, _sort.sortVersions)(out.filter(function (v) {
        return !v.endsWith('.x');
      })).reverse()));
      var mappedVersions = out.map(function (v) {
        var label = v;
        var out = null;
        var version = (0, _parseVersion.coerceVersion)(v);

        if (!label.startsWith('v')) {
          label = "v".concat(label);
        }

        if (label.endsWith('.x')) {
          label = _this2.intl.t('formVersions.dotx', {
            minor: label.replace(/\.x$/, '')
          });
        }

        if ((0, _parseVersion.satisfies)(version, supportedVersionsRange)) {
          if (editing && (0, _semver.lt)(version, (0, _parseVersion.coerceVersion)(initialWithoutX))) {
            if ((0, _semver.minor)(version) < (0, _semver.minor)((0, _parseVersion.coerceVersion)(initialWithoutX))) {
              out = {
                disabled: true,
                label: "".concat(label, " ").concat(_this2.intl.t('formVersions.downgrade')),
                value: v
              };
            } else {
              out = {
                label: label,
                value: v
              };
            }
          } else {
            out = {
              label: label,
              value: v
            };
          }
        } else {
          var suffix = showNotAllowed ? 'formVersions.notallowed' : 'formVersions.unsupported';

          if ((0, _semver.gt)(version, (0, _parseVersion.coerceVersion)(maxVersion))) {
            if (overrideMatch && !Ember.isEmpty(overrideMatch.satisfies)) {
              out = {
                disabled: true,
                label: "".concat(label, " ").concat(_this2.intl.t(suffix)),
                value: v
              };
            } else {
              if ((0, _parseVersion.satisfies)((0, _parseVersion.coerceVersion)(v), '>=1.16 <1.17')) {
                out = {
                  label: label,
                  value: v
                };
              } else {
                out = {
                  experimental: true,
                  label: "".concat(label, " ").concat(_this2.intl.t('formVersions.experimental')),
                  value: v
                };
              }
            }
          } else if ((0, _semver.lt)(version, (0, _parseVersion.coerceVersion)(maxVersion))) {
            out = {
              disabled: true,
              label: "".concat(label, " ").concat(_this2.intl.t(suffix)),
              value: v
            };
          } else {
            out = {
              label: label,
              value: v
            };
          }
        }

        return out;
      });

      if (!editing && defaultK8sVersionRange && this.value) {
        if (!applyClusterTemplate && this.value.endsWith('.x')) {
          Ember.set(this, 'value', this.intl.t('formVersions.dotx', {
            minor: this.value.replace(/\.x$/, '')
          }));
        } else {
          if (maxVersion && !mappedVersions.findBy('value', Ember.get(this, 'value'))) {
            Ember.set(this, 'value', maxVersion);
          }
        }
      }

      if (editing && this.value && this.value.endsWith('.x')) {
        if (applyClusterTemplate && overrideMatch && overrideMatch.satisfies) {
          if (!(0, _parseVersion.satisfies)(initialVersion, overrideMatch.satisfies)) {
            Ember.set(this, 'value', maxVersion);
          } else {
            Ember.set(this, 'value', initialVersion);
          }
        } else {
          if (initialVersion) {
            Ember.set(this, 'value', initialVersion);
          }
        }
      }

      Ember.set(this, 'versionChoices', mappedVersions);
    }
  });

  _exports.default = _default;
});