define("shared/helpers/maybe-t", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params) {
      var key = params[0];
      var fallback = params[1];
      var intl = Ember.get(this, 'intl');

      if (key && intl.locale && intl.exists(key)) {
        return intl.t(key);
      } else if (key && !fallback) {
        return key;
      } else {
        return fallback;
      }
    },
    onRecompute: Ember.observer('intl.locale', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});