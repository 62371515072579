define('ember-intl/-private/normalize-locale', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (localeName) {
    if (typeof localeName === 'string') {
      return localeName.replace(/_/g, '-').toLowerCase();
    }
  };
});