define('ember-intl/-private/formatters/format-date', ['exports', 'intl-format-cache', 'ember-intl/-private/formatters/-base'], function (exports, _intlFormatCache, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var FormatDate = function (_Formatter) {
    _inherits(FormatDate, _Formatter);

    function FormatDate() {
      _classCallCheck(this, FormatDate);

      var _this = _possibleConstructorReturn(this, (FormatDate.__proto__ || Object.getPrototypeOf(FormatDate)).call(this));

      _this.formatter = (0, _intlFormatCache.default)(Intl.DateTimeFormat);
      return _this;
    }

    _createClass(FormatDate, [{
      key: 'format',
      value: function format(value, options, ctx) {
        var dateTime = new Date(value);
        var formatOptions = this.readOptions(options);

        return this._format(dateTime, formatOptions, undefined, ctx);
      }
    }, {
      key: 'options',
      get: function get() {
        return Ember.A(['locale', 'format', 'localeMatcher', 'timeZone', 'hour12', 'formatMatcher', 'weekday', 'era', 'year', 'month', 'day', 'hour', 'minute', 'second', 'timeZoneName']);
      }
    }]);

    return FormatDate;
  }(_base.default);

  exports.default = FormatDate;
});