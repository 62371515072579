define('ember-intl/services/intl', ['exports', 'intl-messageformat', 'intl-relativeformat', 'ember-intl/-private/formatters', 'ember-intl/utils/links', 'ember-intl/-private/is-array-equal', 'ember-intl/-private/normalize-locale'], function (exports, _intlMessageformat, _intlRelativeformat, _formatters, _links, _isArrayEqual, _normalizeLocale) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function formatter(name) {
    return function (value, options, formats) {
      var formatOptions = options;

      if (options && typeof options.format === 'string') {
        formatOptions = Ember.assign({}, this.getFormat(name, formatOptions.format), formatOptions);
      }

      return this._formatters[name].format(value, formatOptions, {
        formats: formats || Ember.get(this, 'formats'),
        locale: this._localeWithDefault(formatOptions && formatOptions.locale)
      });
    };
  }

  var IntlService = Ember.Service.extend(Ember.Evented, {
    _locale: null,

    /** @public **/
    locale: Ember.computed('_locale', {
      set: function set() {
        throw new Error('Use `setLocale` to change the application locale');
      },
      get: function get() {
        return Ember.get(this, '_locale');
      }
    }),

    /** @private **/
    adapter: Ember.computed({
      get: function get() {
        return this._owner.lookup('ember-intl@adapter:default');
      }
    }),

    /** @public **/
    formats: Ember.computed({
      get: function get() {
        return this._owner.resolveRegistration('formats:main') || {};
      }
    }),

    /** @public **/
    formatHtmlMessage: formatter('html-message'),

    /** @public **/
    formatRelative: formatter('relative'),

    /** @public **/
    formatMessage: formatter('message'),

    /** @public **/
    formatNumber: formatter('number'),

    /** @public **/
    formatTime: formatter('time'),

    /** @public **/
    formatDate: formatter('date'),

    /**
     * Returns an array of registered locale names
     *
     * @property locales
     * @public
     */
    locales: Ember.computed.readOnly('adapter.locales'),

    init: function init() {
      this._super();

      if (typeof Intl === 'undefined') {
        (false && Ember.warn('[ember-intl] Intl API was not found.\nSee: ' + _links.default.polyfill, false, {
          id: 'ember-intl-undefined-intljs'
        }));
      }

      this._owner = Ember.getOwner(this);

      this._formatters = {
        'html-message': new _formatters.FormatHtmlMessage(),
        message: new _formatters.FormatMessage(),
        relative: new _formatters.FormatRelative(),
        number: new _formatters.FormatNumber(),
        time: new _formatters.FormatTime(),
        date: new _formatters.FormatDate()
      };

      this._hydrate();
    },
    _hydrate: function _hydrate() {
      var _this = this;

      var config = this._owner.resolveRegistration('config:environment');
      var cldrs = this._lookupByFactoryType('cldrs', config.modulePrefix);
      var translations = this._lookupByFactoryType('translations', config.modulePrefix);

      if (!cldrs.length) {
        (false && Ember.warn('[ember-intl] project is missing CLDR data\nIf you are asynchronously loading translation,\n        see: ' + _links.default.asyncTranslations + '.', false, {
          id: 'ember-intl-missing-cldr-data'
        }));
      }

      cldrs.map(function (moduleName) {
        return _this._owner.resolveRegistration('cldr:' + moduleName.split('/').pop());
      }).forEach(function (data) {
        return data.forEach(_this.addLocaleData);
      });

      translations.forEach(function (moduleName) {
        var localeName = moduleName.split('/').pop();

        _this.addTranslations(localeName, _this._owner.resolveRegistration('translation:' + localeName));
      });
    },
    _lookupByFactoryType: function _lookupByFactoryType(type, modulePrefix) {
      return Object.keys(requirejs.entries).filter(function (key) {
        return key.indexOf(modulePrefix + '/' + type + '/') === 0;
      });
    },
    _localeWithDefault: function _localeWithDefault(localeName) {
      if (!localeName) {
        return Ember.get(this, '_locale') || [];
      }

      if (typeof localeName === 'string') {
        return Ember.makeArray(localeName).map(_normalizeLocale.default);
      }

      if (Array.isArray(localeName)) {
        return localeName.map(_normalizeLocale.default);
      }
    },
    lookup: function lookup(key, localeName) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var localeNames = this._localeWithDefault(localeName);
      var translation = Ember.get(this, 'adapter').lookup(localeNames, key);

      if (!options.resilient && translation === undefined) {
        var missingMessage = this._owner.resolveRegistration('util:intl/missing-message');

        return missingMessage.call(this, key, localeNames);
      }

      return translation;
    },
    t: function t(key) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      var _args = _slicedToArray(args, 1),
          options = _args[0];

      var translation = this.lookup(key, options && options.locale, {
        resilient: options && typeof options.fallback === 'string'
      });

      var value = typeof translation === 'string' ? translation : options.fallback;

      if (options && options.htmlSafe) {
        return this.formatHtmlMessage.apply(this, [value].concat(_toConsumableArray(args)));
      }

      return this.formatMessage.apply(this, [value].concat(_toConsumableArray(args)));
    },
    exists: function exists(key, localeName) {
      var localeNames = this._localeWithDefault(localeName);
      var adapter = Ember.get(this, 'adapter');

      (false && !(Array.isArray(localeNames) && localeNames.length) && Ember.assert('[ember-intl] locale is unset, cannot lookup \'' + key + '\'', Array.isArray(localeNames) && localeNames.length));


      return localeNames.some(function (localeName) {
        return adapter.has(localeName, key);
      });
    },
    getLocalesByTranslations: function getLocalesByTranslations() {
      Ember.deprecate('[ember-intl] `getLocalesByTranslations` is deprecated, use `locales` computed property', false, {
        id: 'ember-intl-locales-cp',
        until: '3.0.0'
      });

      return Ember.get(this, 'locales');
    },
    addLocaleData: function addLocaleData(data) {
      _intlMessageformat.default.__addLocaleData(data);
      _intlRelativeformat.default.__addLocaleData(data);
    },
    addTranslation: function addTranslation(localeName, key, value) {
      return this.localeFactory(localeName).then(function (locale) {
        return locale.addTranslation(key, value);
      });
    },
    addTranslations: function addTranslations(localeName, payload) {
      return this.localeFactory(localeName).then(function (locale) {
        return locale.addTranslations(payload);
      });
    },
    setLocale: function setLocale(localeName) {
      var proposed = Ember.makeArray(localeName).map(_normalizeLocale.default);
      var current = Ember.get(this, '_locale');

      if (!(0, _isArrayEqual.default)(proposed, current)) {
        Ember.set(this, '_locale', proposed);
        this.notifyPropertyChange('locale');
        this.trigger('localeChanged');
      }
    },
    getFormat: function getFormat(formatType, format) {
      var formats = Ember.get(this, 'formats');

      if (formats && formatType && typeof format === 'string') {
        return Ember.get(formats, formatType + '.' + format);
      }
    },
    localeFactory: function localeFactory(localeName) {
      return Ember.RSVP.resolve(Ember.get(this, 'adapter').localeFactory((0, _normalizeLocale.default)(localeName), true));
    },
    createLocale: function createLocale(localeName, payload) {
      Ember.deprecate('[ember-intl] `createLocale` is deprecated, use `addTranslations`', false, {
        id: 'ember-intl-create-locale',
        until: '3.0.0'
      });

      return this.addTranslations(localeName, payload);
    },
    findTranslationByKey: function findTranslationByKey(key, localeName, options) {
      return this.lookup(key, localeName, options);
    },
    translationsFor: function translationsFor(localeName) {
      return this.localeFactory(localeName);
    }
  });

  exports.default = IntlService;
});