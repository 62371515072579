define('ember-intl/-private/is-array-equal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (a, b) {
    if (!Ember.isArray(a) || !Ember.isArray(b)) {
      return false;
    }
    if (a === b) {
      return true;
    }

    return a.toString() === b.toString();
  };
});