define("shared/components/node-pool-row/component", ["exports", "shared/components/node-pool-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    tagName: 'tr',
    classNames: ['main-row'],
    pool: null,
    taintCapabilites: Ember.computed.alias('scope.currentCluster.capabilities.taintSupport'),
    deleteNotReadyAfterSecs: Ember.computed('pool.deleteNotReadyAfterSecs', {
      get: function get() {
        var _this$pool$deleteNotR = this.pool.deleteNotReadyAfterSecs,
            deleteNotReadyAfterSecs = _this$pool$deleteNotR === void 0 ? 0 : _this$pool$deleteNotR;
        return deleteNotReadyAfterSecs / 60;
      },
      set: function set(key, value) {
        var out = value * 60;
        Ember.set(this, 'pool.deleteNotReadyAfterSecs', out);
        return value;
      }
    }),
    removePool: function removePool() {
      throw new Error('removePool action is required!');
    },
    addNodeTemplate: function addNodeTemplate() {
      throw new Error('addNodeTemplate action is required!');
    },
    configAdvancedOptions: function configAdvancedOptions() {
      throw new Error('configAdvancedOptions action is required!');
    }
  });

  _exports.default = _default;
});