define("shared/oauth/service", ["exports", "shared/utils/util", "shared/utils/constants"], function (_exports, _util, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var googleOauthScope = 'openid profile email https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/admin.directory.group.readonly';
  var githubOauthScope = 'read:org';

  var _default = Ember.Service.extend({
    access: Ember.inject.service(),
    cookies: Ember.inject.service(),
    session: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    app: Ember.inject.service(),
    intl: Ember.inject.service(),
    authType: '',
    generateState: function generateState() {
      return Ember.set(this, 'session.oauthState', "".concat(Math.random()));
    },
    generateLoginStateKey: function generateLoginStateKey(authType) {
      return Ember.set(this, 'session.oauthState', "".concat(Math.random(), "login").concat(authType));
    },
    stateMatches: function stateMatches(actual) {
      return actual && Ember.get(this, 'session.oauthState') === actual;
    },
    testConfig: function testConfig(config) {
      return config.doAction('configureTest', config);
    },
    saveConfig: function saveConfig(config, opt) {
      return config.doAction('testAndApply', opt);
    },
    authorize: function authorize(auth, state) {
      var isGithub = auth.type.includes('github');
      var url = null;

      if (isGithub) {
        url = (0, _util.addQueryParams)(Ember.get(auth, 'redirectUrl'), {
          scope: githubOauthScope,
          redirect_uri: "".concat(window.location.origin, "/verify-auth"),
          authProvider: 'github',
          state: state
        });
      } else {
        url = (0, _util.addQueryParams)(Ember.get(auth, 'redirectUrl'), {
          scope: googleOauthScope,
          redirect_uri: "".concat(window.location.origin, "/verify-auth"),
          state: state
        });
      }

      return window.location.href = url;
    },
    login: function login(authType, forwardUrl) {
      var provider = Ember.get(this, 'access.providers').findBy('id', authType);
      var authRedirect = Ember.get(provider, 'redirectUrl');
      var redirect = "".concat(window.location.origin, "/verify-auth");

      if (forwardUrl) {
        redirect = (0, _util.addQueryParam)(redirect, 'forward', forwardUrl);
      }

      var url = (0, _util.addQueryParams)(authRedirect, {
        scope: authType === 'github' ? githubOauthScope : googleOauthScope,
        state: this.generateLoginStateKey(authType),
        redirect_uri: redirect
      });
      window.location.href = url;
    },
    test: function test(config, cb) {
      var _this = this;

      var responded = false;
      var configName = config.name;

      window.onAuthTest = function (err, code) {
        if (!responded && !err) {
          var ghConfig = config;
          responded = true;

          _this.finishTest(ghConfig, code, cb);
        }
      };

      Ember.set(this, 'state', this.generateState());
      var url = (0, _util.addQueryParams)("".concat(window.location.origin, "/verify-auth"), {
        config: configName
      });
      var popup = window.open(url, 'rancherAuth', (0, _util.popupWindowOptions)());
      var intl = Ember.get(this, 'intl');
      var timer = setInterval(function () {
        if (popup && popup.closed) {
          clearInterval(timer);

          if (!responded) {
            responded = true;
            cb({
              type: 'error',
              message: intl.t("authPage.".concat(configName, ".testAuth.authError"))
            });
          }
        } else if (popup === null || typeof popup === 'undefined') {
          clearInterval(timer);

          if (!responded) {
            responded = true;
            cb({
              type: 'error',
              message: intl.t("authPage.".concat(configName, ".testAuth.popupError"))
            });
          }
        }
      }, 500);
    },
    finishTest: function finishTest(config, code, cb) {
      var _this2 = this;

      var currentConfig = config;
      var out = null;
      Ember.set(currentConfig, 'enabled', true);

      if (config.id === 'googleoauth') {
        out = {
          code: code,
          enabled: true,
          googleOauthConfig: currentConfig,
          description: _constants.default.SESSION.DESCRIPTION,
          ttl: _constants.default.SESSION.TTL
        };
      } else {
        out = {
          code: code,
          enabled: true,
          githubConfig: currentConfig,
          description: _constants.default.SESSION.DESCRIPTION,
          ttl: _constants.default.SESSION.TTL
        };
      }

      var allowedPrincipalIds = Ember.get(config, 'allowedPrincipalIds') || [];
      return this.saveConfig(config, out).then(function () {
        var found = false;
        var myPIds = Ember.get(_this2, 'access.me.principalIds');
        myPIds.forEach(function (id) {
          if (allowedPrincipalIds.indexOf(id) >= 0) {
            found = true;
          }
        });

        if (!found && !allowedPrincipalIds.length) {
          allowedPrincipalIds.pushObject(Ember.get(_this2, 'access.principal.id'));
        }

        return currentConfig.save().then(function () {
          window.location.href = window.location.href;
        });
      }).catch(function (err) {
        cb(err);
      });
    }
  });

  _exports.default = _default;
});