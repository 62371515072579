define('ember-intl/-private/formatters/-base', ['exports', 'ember-intl/utils/links'], function (exports, _links) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var EMPTY_OBJECT = {};

  var FormatterBase = function () {
    function FormatterBase() {
      _classCallCheck(this, FormatterBase);
    }

    _createClass(FormatterBase, [{
      key: 'readOptions',
      value: function readOptions(options) {
        if (!options) {
          return EMPTY_OBJECT;
        }

        var found = {};

        for (var key in options) {
          var normalized = Ember.String.camelize(key);

          if (this.options.includes(normalized)) {
            found[normalized] = options[key];
          }
        }

        return found;
      }
    }, {
      key: 'format',
      value: function format() {
        throw new Error('not implemented');
      }
    }, {
      key: '_format',
      value: function _format(value, formatterOptions, formatOptions, _ref) {
        var locale = _ref.locale;

        if (!locale) {
          throw new Error('No locale specified.  This is typically handled within routes/application.js.\n        Documentation: ' + _links.default.unsetLocale);
        }

        return this.formatter(locale, formatterOptions).format(value, formatOptions);
      }
    }, {
      key: 'options',
      get: function get() {
        return Ember.A();
      }
    }]);

    return FormatterBase;
  }();

  exports.default = FormatterBase;
});