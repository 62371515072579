define("global-admin/cluster-templates/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Op5yRMtt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"header clearfix\"],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"pull-left\"],[9],[0,\"\\n    \"],[1,[23,[\"model\",\"clusterTemplate\",\"displayName\"]],false],[0,\": \"],[1,[23,[\"model\",\"clusterTemplateRevision\",\"displayName\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"cru-cluster-template\",[],[[\"@clusterTemplate\",\"@clusterTemplateRevision\",\"@clusterTemplateRevisionId\",\"@psps\",\"@mode\",\"@cancel\",\"@done\"],[[23,[\"model\",\"clusterTemplate\"]],[23,[\"model\",\"clusterTemplateRevision\"]],[23,[\"model\",\"clusterTemplateRevisionId\"]],[23,[\"model\",\"psps\"]],\"view\",[27,\"action\",[[22,0,[]],\"cancel\"],null],[27,\"action\",[[22,0,[]],\"done\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/cluster-templates/detail/template.hbs"
    }
  });

  _exports.default = _default;
});