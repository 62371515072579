define("shared/azure-ad/service", ["exports", "shared/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var additionalRedirectParams = {
    response_mode: 'query',
    response_type: 'code' // prompt: "consent",

  };

  var _default = Ember.Service.extend({
    access: Ember.inject.service(),
    cookies: Ember.inject.service(),
    session: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    app: Ember.inject.service(),
    intl: Ember.inject.service(),
    testConfig: function testConfig(config) {
      return config.doAction('configureTest', config);
    },
    saveConfig: function saveConfig(config, opt) {
      return config.doAction('testAndApply', opt);
    },
    login: function login() {
      var provider = Ember.get(this, 'access.providers').findBy('id', 'azuread');
      var authRedirect = Ember.get(provider, 'redirectUrl');

      var redirect = _util.default.addQueryParams(authRedirect, additionalRedirectParams);

      window.location.href = redirect;
    },
    test: function test(config, url, cb) {
      var _this = this;

      var responded = false;

      window.onAzureTest = function (err, code) {
        if (!responded) {
          var azureADConfig = config;
          responded = true;

          _this.finishTest(azureADConfig, code, cb);
        }
      };

      url = _util.default.addQueryParams(url, additionalRedirectParams);
      var popup = window.open(url, 'rancherAuth', _util.default.popupWindowOptions());
      var intl = Ember.get(this, 'intl');
      var timer = setInterval(function () {
        if (popup && popup.closed) {
          clearInterval(timer);

          if (!responded) {
            responded = true;
            cb({
              type: 'error',
              message: intl.t('authPage.azuread.test.authError')
            });
          }
        } else if (popup === null || typeof popup === 'undefined') {
          clearInterval(timer);

          if (!responded) {
            responded = true;
            cb({
              type: 'error',
              message: intl.t('authPage.azuread.test.popupError')
            });
          }
        }
      }, 500);
    },
    finishTest: function finishTest(config, code, cb) {
      var _this2 = this;

      var azureADConfig = config;
      Ember.set(azureADConfig, 'enabled', true);
      var out = {
        code: code,
        config: azureADConfig
      };
      return this.saveConfig(config, out).then(function () {
        return Ember.get(_this2, 'globalStore').find('principal', null, {
          filter: {
            me: true,
            provider: 'azuread'
          }
        }).then(function (resp) {
          var aps = Ember.get(out, 'config.allowedPrincipalIds') ? Ember.get(out, 'config.allowedPrincipalIds') : Ember.set(out, 'config.allowedPrincipalIds', []);
          var me = resp.find(function (p) {
            return Ember.get(p, 'me') && Ember.get(p, 'provider') === 'azuread'; // TODO  filters do not work but craig knows
          });

          if (!aps.includes(Ember.get(me, 'id'))) {
            aps.pushObject(Ember.get(me, 'id'));
          }

          return azureADConfig.save().then(function () {
            window.location.href = window.location.href;
          });
        });
      }).catch(function (err) {
        return cb(err);
      });
    }
  });

  _exports.default = _default;
});