define("global-admin/components/registry-catalog/component", ["exports", "global-admin/components/registry-catalog/template", "shared/mixins/crud-catalog", "shared/utils/util", "ui/utils/constants", "shared/mixins/reservation-check", "shared/utils/parse-unit"], function (_exports, _template, _crudCatalog, _util, _constants, _reservationCheck, _parseUnit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _HIDDEN_KEYS, _ANSWER_TO_CONFIG;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var INTERNAL = 'internal';
  var INGRESS_HOSTS_CORE = 'expose.ingress.host';
  var APP_VERSION = 'catalog://?catalog=system-library&template=harbor&version=1.7.5-rancher1';
  var STORAGE_TYPE = ['filesystem', 's3'];
  var S3_REGION = _constants.default.AWS_S3_REGIONS;
  var ACCESS_MODE = ['ReadWriteOnce'];
  var RDISE_EXTENNAL_KEYS = ['host', 'port', 'jobserviceDatabaseIndex', 'registryDatabaseIndex', 'password'];
  var PERSISTENCE_KEYS = ['existingClaim', 'size', 'storageClass'];
  var DATABASE_EXTERNAL_KEYS = ['host', 'port', 'username', 'password', 'coreDatabase', 'clairDatabase', 'notaryServerDatabase', 'notarySignerDatabase', 'sslmode'];
  var S3_KEYS = ['region', 'bucket', 'accesskey', 'secretkey', 'regionendpoint'];
  var DATABASE_TYPE = [INTERNAL, 'external'];
  var SSL_MODE = _constants.default.POSTGRESQL_SSL_MODE;
  var PERSISTENCE_COMPONENTS = ['redis', 'registry', 'database'];
  var DATABASE_REQUEST_CPU = 'database.internal.resources.requests.cpu';
  var DATABASE_REQUEST_MEM = 'database.internal.resources.requests.memory';
  var DATABASE_LIMIT_CPU = 'database.internal.resources.limits.cpu';
  var DATABASE_LIMIT_MEM = 'database.internal.resources.limits.memory';
  var REDIS_REQUEST_CPU = 'redis.internal.resources.requests.cpu';
  var REDIS_REQUEST_MEM = 'redis.internal.resources.requests.memory';
  var REDIS_LIMIT_CPU = 'redis.internal.resources.limits.cpu';
  var REDIS_LIMIT_MEM = 'redis.internal.resources.limits.memory';
  var REGISTRY_REQUEST_CPU = 'registry.registry.resources.requests.cpu';
  var REGISTRY_REQUEST_MEM = 'registry.registry.resources.requests.memory';
  var REGISTRY_LIMIT_CPU = 'registry.registry.resources.limits.cpu';
  var REGISTRY_LIMIT_MEM = 'registry.registry.resources.limits.memory';
  var CLAIR_REQUEST_CPU = 'clair.resources.requests.cpu';
  var CLAIR_REQUEST_MEM = 'clair.resources.requests.memory';
  var CLAIR_LIMIT_CPU = 'clair.resources.limits.cpu';
  var CLAIR_LIMIT_MEM = 'clair.resources.limits.memory';
  var NOTARY_REQUEST_CPU = 'notary.server.resources.requests.cpu';
  var NOTARY_REQUEST_MEM = 'notary.server.resources.requests.memory';
  var NOTARY_LIMIT_CPU = 'notary.server.resources.limits.cpu';
  var NOTARY_LIMIT_MEM = 'notary.server.resources.limits.memory';
  var SIGNER_REQUEST_CPU = 'notary.signer.resources.requests.cpu';
  var SIGNER_REQUEST_MEM = 'notary.signer.resources.requests.memory';
  var SIGNER_LIMIT_CPU = 'notary.signer.resources.limits.cpu';
  var SIGNER_LIMIT_MEM = 'notary.signer.resources.limits.memory';
  var REGISTRY_NODE_SELECTOR_PREFIX = 'registry.nodeSelector.';
  var DATABASE_NODE_SELECTOR_PREFIX = 'database.internal.nodeSelector.';
  var REDIS_NODE_SELECTOR_PREFIX = 'redis.internal.nodeSelector.';
  var CLAIR_NODE_SELECTOR_PREFIX = 'clair.nodeSelector.';
  var NOTARY_NODE_SELECTOR_PREFIX = 'notary.nodeSelector.';
  var WORKLOADS = ['database', 'redis', 'registry', 'clair', 'notaryServer', 'notarySigner'];
  var HIDDEN_KEYS = (_HIDDEN_KEYS = {
    'externalURL': "https://".concat(window.location.host)
  }, _defineProperty(_HIDDEN_KEYS, INGRESS_HOSTS_CORE, window.location.host), _defineProperty(_HIDDEN_KEYS, 'persistence.type', 'storageClass'), _HIDDEN_KEYS);
  var ANSWER_TO_CONFIG = (_ANSWER_TO_CONFIG = {
    secretKey: 'secretKey',
    'imageChartStorage.type': 'storageType',
    'database.type': 'databaseType',
    'redis.type': 'redisType',
    'clair.enabled': 'clairEnabled',
    'notary.enabled': 'notaryEnabled',
    'harborAdminPassword': 'password'
  }, _defineProperty(_ANSWER_TO_CONFIG, DATABASE_REQUEST_CPU, 'databaseRequestCpu'), _defineProperty(_ANSWER_TO_CONFIG, DATABASE_REQUEST_MEM, 'databaseRequestMemory'), _defineProperty(_ANSWER_TO_CONFIG, DATABASE_LIMIT_CPU, 'databaseLimitCpu'), _defineProperty(_ANSWER_TO_CONFIG, DATABASE_LIMIT_MEM, 'databaseLimitMemory'), _defineProperty(_ANSWER_TO_CONFIG, REDIS_REQUEST_CPU, 'redisRequestCpu'), _defineProperty(_ANSWER_TO_CONFIG, REDIS_REQUEST_MEM, 'redisRequestMemory'), _defineProperty(_ANSWER_TO_CONFIG, REDIS_LIMIT_CPU, 'redisLimitCpu'), _defineProperty(_ANSWER_TO_CONFIG, REDIS_LIMIT_MEM, 'redisLimitMemory'), _defineProperty(_ANSWER_TO_CONFIG, REGISTRY_REQUEST_CPU, 'registryRequestCpu'), _defineProperty(_ANSWER_TO_CONFIG, REGISTRY_REQUEST_MEM, 'registryRequestMemory'), _defineProperty(_ANSWER_TO_CONFIG, REGISTRY_LIMIT_CPU, 'registryLimitCpu'), _defineProperty(_ANSWER_TO_CONFIG, REGISTRY_LIMIT_MEM, 'registryLimitMemory'), _defineProperty(_ANSWER_TO_CONFIG, CLAIR_REQUEST_CPU, 'clairRequestCpu'), _defineProperty(_ANSWER_TO_CONFIG, CLAIR_REQUEST_MEM, 'clairRequestMemory'), _defineProperty(_ANSWER_TO_CONFIG, CLAIR_LIMIT_CPU, 'clairLimitCpu'), _defineProperty(_ANSWER_TO_CONFIG, CLAIR_LIMIT_MEM, 'clairLimitMemory'), _defineProperty(_ANSWER_TO_CONFIG, NOTARY_REQUEST_CPU, 'notaryServerRequestCpu'), _defineProperty(_ANSWER_TO_CONFIG, NOTARY_REQUEST_MEM, 'notaryServerRequestMemory'), _defineProperty(_ANSWER_TO_CONFIG, NOTARY_LIMIT_CPU, 'notaryServerLimitCpu'), _defineProperty(_ANSWER_TO_CONFIG, NOTARY_LIMIT_MEM, 'notaryServerLimitMemory'), _defineProperty(_ANSWER_TO_CONFIG, SIGNER_REQUEST_CPU, 'notarySignerRequestCpu'), _defineProperty(_ANSWER_TO_CONFIG, SIGNER_REQUEST_MEM, 'notarySignerRequestMemory'), _defineProperty(_ANSWER_TO_CONFIG, SIGNER_LIMIT_CPU, 'notarySignerLimitCpu'), _defineProperty(_ANSWER_TO_CONFIG, SIGNER_LIMIT_MEM, 'notarySignerLimitMemory'), _ANSWER_TO_CONFIG);

  var _default = Ember.Component.extend(_crudCatalog.default, _reservationCheck.default, {
    intl: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    answers: null,
    appName: 'global-registry',
    nsName: 'cattle-system',
    appVersion: APP_VERSION,
    nsExists: true,
    config: null,
    level: 'cluster',
    clusterLevelMinCpu: 500,
    clusterLevelMinMemory: 500,
    init: function init() {
      this._super.apply(this, arguments);

      this.initWorkloads();

      if (Ember.get(this, 'showForm')) {
        this.initConfig();
      } else {
        this.clearTimeOut();
        var _this$globalRegistryE = this.globalRegistryEnabled,
            globalRegistryEnabled = _this$globalRegistryE === void 0 ? {} : _this$globalRegistryE;
        Ember.setProperties(this, {
          enabled: globalRegistryEnabled.value === 'true',
          ready: true
        });
      }
    },
    actions: {
      save: function save(cb) {
        var _this = this;

        Ember.set(this, 'errors', []);
        var errors = this.validate() || [];

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();
          return;
        }

        var answers = _objectSpread({}, HIDDEN_KEYS);

        var answerKeys = Object.keys(ANSWER_TO_CONFIG) || [];
        answerKeys.map(function (key) {
          var value = Ember.get(_this, "config.".concat(ANSWER_TO_CONFIG[key]));

          if (value === undefined || value === '') {
            return;
          }

          switch (key) {
            case DATABASE_REQUEST_CPU:
            case DATABASE_LIMIT_CPU:
            case REDIS_REQUEST_CPU:
            case REDIS_LIMIT_CPU:
            case REGISTRY_REQUEST_CPU:
            case REGISTRY_LIMIT_CPU:
            case CLAIR_REQUEST_CPU:
            case CLAIR_LIMIT_CPU:
            case NOTARY_REQUEST_CPU:
            case NOTARY_LIMIT_CPU:
            case SIGNER_REQUEST_CPU:
            case SIGNER_LIMIT_CPU:
              answers[key] = "".concat(value, "m");
              break;

            case DATABASE_REQUEST_MEM:
            case DATABASE_LIMIT_MEM:
            case REDIS_REQUEST_MEM:
            case REDIS_LIMIT_MEM:
            case REGISTRY_REQUEST_MEM:
            case REGISTRY_LIMIT_MEM:
            case CLAIR_REQUEST_MEM:
            case CLAIR_LIMIT_MEM:
            case NOTARY_REQUEST_MEM:
            case NOTARY_LIMIT_MEM:
            case SIGNER_REQUEST_MEM:
            case SIGNER_LIMIT_MEM:
              answers[key] = "".concat(value, "Mi");
              break;

            default:
              answers[key] = value;
          }
        });

        var _EmberGet = Ember.get(this, 'config'),
            databaseType = _EmberGet.databaseType,
            redisType = _EmberGet.redisType,
            storageType = _EmberGet.storageType;

        if (storageType === 's3') {
          S3_KEYS.map(function (k) {
            var key = "imageChartStorage.s3.".concat(k);
            answers[key] = Ember.get(_this, key);
          });
        } else if (storageType === 'filesystem') {
          this.willSavePersistence(answers, 'registry');
        }

        if (databaseType === 'external') {
          DATABASE_EXTERNAL_KEYS.map(function (k) {
            var key = "database.external.".concat(k);
            answers[key] = Ember.get(_this, key);
          });
        } else if (databaseType === INTERNAL) {
          this.willSavePersistence(answers, 'database');
        }

        if (redisType === 'external') {
          RDISE_EXTENNAL_KEYS.map(function (k) {
            var key = "redis.external.".concat(k);
            answers[key] = Ember.get(_this, key);
          });
          answers['redis.external.coreDatabaseIndex'] = '0';
        } else if (redisType === INTERNAL) {
          this.willSavePersistence(answers, 'redis');
        }

        ['registry', 'database', 'redis', 'clair', 'notary'].map(function (component) {
          (Ember.get(_this, "".concat(component, "NodeSelectors")) || []).map(function (selector) {
            var key = selector.key,
                value = selector.value;

            if (key.includes('.')) {
              key = key.replace(/\./g, '\\.');
            }

            if (component === 'registry') {
              answers["registry.nodeSelector.".concat(key)] = value;
            } else if (['database', 'redis'].includes(component) && Ember.get(_this, "config.".concat(component, "Type")) === INTERNAL) {
              answers["".concat(component, ".internal.nodeSelector.").concat(key)] = value;
            } else if (['clair', 'notary'].includes(component) && Ember.get(_this, "config.".concat(component, "Enabled"))) {
              answers["".concat(component, ".nodeSelector.").concat(key)] = value;
            }
          });
        });
        this.save(cb, answers);
      },
      disable: function disable() {
        var _this2 = this;

        var url = Ember.get(this, 'app.links.self');
        Ember.get(this, 'globalStore').rawRequest({
          url: url,
          method: 'DELETE'
        }).then(function () {
          _this2.setGlobalRegistryEnabled('false', function () {
            setTimeout(function () {
              window.location.href = window.location.href;
            }, 1000);
          });
        }).catch(function (err) {
          Ember.get(_this2, 'growl').fromError(Ember.get(err, 'body.message'));
        });
      }
    },
    workloadEnabledChange: Ember.observer('config.clairEnabled', 'config.notaryEnabled', 'config.databaseType', 'config.redisType', 'config.storageType', function () {
      var _this3 = this;

      ['clair', 'notary'].map(function (w) {
        if (!Ember.get(_this3, "config.".concat(w, "Enabled"))) {
          Ember.set(_this3, "".concat(w, "NodeSelectors"), []);
        }
      });
      ['database', 'redis'].map(function (w) {
        if (Ember.get(_this3, "config.".concat(w, "Type")) === 'external') {
          Ember.set(_this3, "".concat(w, "NodeSelectors"), []);
        }
      });
      Ember.set(this, "registryNodeSelectors", []);
      this.notifyPropertyChange('requestsCpu');
      this.notifyPropertyChange('requestsMemory');
      this.notifyPropertyChange('saveDisabled');
    }),
    dockerLogin: Ember.computed('app.answers', function () {
      var url = (Ember.get(this, 'app.answers') || HIDDEN_KEYS)[INGRESS_HOSTS_CORE];
      var username = Ember.get(this, 'showForm') ? 'admin' : 'USERNAME';
      return "docker login --username=".concat(username, " ").concat(url);
    }),
    dockerPull: Ember.computed('app.answers', function () {
      var url = (Ember.get(this, 'app.answers') || HIDDEN_KEYS)[INGRESS_HOSTS_CORE];
      return "docker pull ".concat(url, "/REPO_NAME/IMAGE_NAME[:TAG]");
    }),
    dockerTag: Ember.computed('app.answers', function () {
      var url = (Ember.get(this, 'app.answers') || HIDDEN_KEYS)[INGRESS_HOSTS_CORE];
      return "docker tag SOURCE_IMAGE[:TAG] ".concat(url, "/REPO_NAME/IMAGE_NAME[:TAG]");
    }),
    dockerPush: Ember.computed('app.answers', function () {
      var url = (Ember.get(this, 'app.answers') || HIDDEN_KEYS)[INGRESS_HOSTS_CORE];
      return "docker push ".concat(url, "/REPO_NAME/IMAGE_NAME[:TAG]");
    }),
    storageTypeContent: Ember.computed(function () {
      return STORAGE_TYPE.map(function (value) {
        return {
          label: value,
          value: value
        };
      });
    }),
    s3RegionContent: Ember.computed(function () {
      return S3_REGION.map(function (value) {
        return {
          label: value,
          value: value
        };
      });
    }),
    accessModeContent: Ember.computed(function () {
      return ACCESS_MODE.map(function (value) {
        return {
          label: value,
          value: value
        };
      });
    }),
    databaseTypeContent: Ember.computed(function () {
      return DATABASE_TYPE.map(function (value) {
        return {
          label: value,
          value: value
        };
      });
    }),
    sslmodeContent: Ember.computed(function () {
      return SSL_MODE.map(function (value) {
        return {
          label: value,
          value: value
        };
      });
    }),
    persistentVolumeChoices: Ember.computed('persistentVolumeClaims.[]', function () {
      return (Ember.get(this, 'persistentVolumeClaims') || []).map(function (s) {
        return {
          label: s.name,
          value: s.id
        };
      });
    }),
    requestsCpu: Ember.computed('config.redisRequestCpu', 'config.databaseRequestCpu', 'config.registryRequestCpu', 'config.notaryServerRequestCpu', 'config.calirRequestCpu', 'config.notarySignerRequestCpu', function () {
      var _this4 = this;

      return WORKLOADS.filter(function (w) {
        if (['notaryServer', 'notarySigner'].includes(w) && !Ember.get(_this4, "config.notaryEnabled")) {
          return false;
        } else if (w === 'clair' && !Ember.get(_this4, "config.clairEnabled")) {
          return false;
        } else if (['redis', 'database'].includes(w) && Ember.get(_this4, "config.".concat(w, "Type")) === 'external') {
          return false;
        }

        return true;
      }).reduce(function (all, w) {
        return all + parseInt(Ember.get(_this4, "config.".concat(w, "RequestCpu")) || 0);
      }, 0);
    }),
    requestsMemory: Ember.computed('config.redisRequestMemory', 'config.databaseRequestMemory', 'config.registryRequestMemory', 'config.clairRequestMemory', 'config.notaryServerRequestMemory', 'config.notarySignerRequestMemory', function () {
      var _this5 = this;

      return WORKLOADS.filter(function (w) {
        if (['notaryServer', 'notarySigner'].includes(w) && !Ember.get(_this5, "config.notaryEnabled")) {
          return false;
        } else if (w === 'clair' && !Ember.get(_this5, "config.clairEnabled")) {
          return false;
        } else if (['redis', 'database'].includes(w) && Ember.get(_this5, "config.".concat(w, "Type")) === 'external') {
          return false;
        }

        return true;
      }).reduce(function (all, w) {
        return all + parseInt(Ember.get(_this5, "config.".concat(w, "RequestMemory")) || 0);
      }, 0);
    }),
    globalRegistryWarning: Ember.computed('insufficientCpu', 'insufficientMemory', function () {
      var insufficientCpu = this.insufficientCpu,
          insufficientMemory = this.insufficientMemory,
          intl = this.intl,
          minCpu = this.minCpu,
          minMemory = this.minMemory,
          enabled = this.enabled;
      var prefix = 'globalRegistryPage.insufficientSize.total';
      var action = enabled ? 'update' : 'enable';

      if (insufficientCpu && insufficientMemory) {
        return intl.t("".concat(prefix, ".all"), {
          minCpu: minCpu,
          minMemory: minMemory,
          action: action
        });
      } else if (insufficientCpu) {
        return intl.t("".concat(prefix, ".cpu"), {
          minCpu: minCpu,
          action: action
        });
      } else if (insufficientMemory) {
        return intl.t("".concat(prefix, ".memory"), {
          minMemory: minMemory,
          action: action
        });
      }
    }),
    saveDisabled: Ember.computed('globalRegistryWarning', 'databaseWarning', 'redisWarning', 'registryWarning', 'clairWarning', 'notaryWarning', 'notarySignerWarning', 'notaryServerWarning', function () {
      var _this6 = this;

      return [].concat(WORKLOADS, ['notary']).reduce(function (out, w) {
        if (w === 'clair' && Ember.get(_this6, "config.clairEnabled")) {
          return out || Ember.get(_this6, "".concat(w, "Warning")) || false;
        } else if (['database', 'redis'].includes(w) && Ember.get(_this6, "config.".concat(w, "Type")) === 'internal') {
          return out || Ember.get(_this6, "".concat(w, "Warning")) || false;
        } else if (['notary', 'notaryServer', 'notarySigner'].includes(w) && Ember.get(_this6, "config.notaryEnabled")) {
          return out || Ember.get(_this6, "".concat(w, "Warning")) || false;
        } else {
          return out || Ember.get(_this6, "".concat(w, "Warning")) || false;
        }
      }, false) || Ember.get(this, 'globalRegistryWarning');
    }),
    notarySchedulableNodes: Ember.computed('notaryNodeSelectors.[]', 'cluster.nodes.@each.{allocatable,requested}', 'config.notaryEnabled', function () {
      if (!Ember.get(this, 'config.notaryEnabled')) {
        return [];
      } else {
        return this.getSchedulableNodes('notary');
      }
    }),
    insufficientNotaryCpu: Ember.computed("notarySchedulableNodes.@each.{allocatable,requested}", "config.notaryServerRequestCpu", 'config.notarySignerRequestCpu', 'cluster.nodes.@each.{allocatable,requested}', function () {
      var reservation = Math.max(parseInt(Ember.get(this, 'config.notaryServerRequestCpu') || '0'), parseInt(Ember.get(this, 'config.notarySignerRequestCpu') || '0'));
      return this.getComponentInsufficient('notary', 'cpu', reservation);
    }),
    insufficientNotaryMemory: Ember.computed("notarySchedulableNodes.@each.{allocatable,requested}", "config.notaryServerRequestMemory", 'config.notarySignerRequestMemory', 'cluster.nodes.@each.{allocatable,requested}', function () {
      var reservation = Math.max(parseInt(Ember.get(this, 'config.notaryServerRequestMemory') || '0'), parseInt(Ember.get(this, 'config.notarySignerRequestMemory') || '0'));
      return this.getComponentInsufficient('notary', 'memory', reservation);
    }),
    notaryWarning: Ember.computed("insufficientNotaryCpu", "insufficientNotaryMemory", function () {
      if (!Ember.get(this, 'config.notaryEnabled')) {
        return;
      }

      var componentCpu = Math.max(parseInt(Ember.get(this, 'config.notaryServerRequestCpu') || '0'), parseInt(Ember.get(this, 'config.notarySignerRequestCpu') || '0'));
      var componentMemory = Math.max(parseInt(Ember.get(this, 'config.notaryServerRequestMemory') || '0'), parseInt(Ember.get(this, 'config.notarySignerRequestMemory') || '0'));
      return this.getComponentWarning('notary', componentCpu, componentMemory, Ember.get(this, 'intl').t('globalRegistryPage.notaryAndSigner'));
    }),
    initConfig: function initConfig() {
      var _this7 = this;

      var customAnswers = {};
      Ember.setProperties(this, {
        redis: {
          external: {}
        },
        persistence: {
          persistentVolumeClaim: {
            registry: {},
            redis: {},
            database: {}
          }
        },
        database: {
          external: {}
        },
        imageChartStorage: {
          s3: {}
        },
        secretKeyQuestion: {
          minLength: 16,
          maxLength: 16,
          validChars: 'alphanum'
        },
        config: {
          clairEnabled: true,
          notaryEnabled: true,
          databaseType: INTERNAL,
          redisType: INTERNAL,
          storageType: 'filesystem',
          databaseRequestCpu: 100,
          databaseRequestMemory: 256,
          databaseLimitCpu: 500,
          databaseLimitMemory: 2048,
          redisRequestCpu: 100,
          redisRequestMemory: 256,
          redisLimitCpu: 500,
          redisLimitMemory: 2048,
          registryRequestCpu: 100,
          registryRequestMemory: 256,
          registryLimitCpu: 1000,
          registryLimitMemory: 2048,
          clairRequestCpu: 100,
          clairRequestMemory: 256,
          clairLimitCpu: 500,
          clairLimitMemory: 2048,
          notaryServerRequestCpu: 100,
          notaryServerRequestMemory: 256,
          notaryServerLimitCpu: 500,
          notaryServerLimitMemory: 2048,
          notarySignerRequestCpu: 100,
          notarySignerRequestMemory: 256,
          notarySignerLimitCpu: 500,
          notarySignerLimitMemory: 2048
        },
        'database.external.sslmode': 'disable',
        'database.external.coreDatabase': 'registry',
        'database.external.clairDatabase': 'clair',
        'database.external.notaryServerDatabase': 'notary_server',
        'database.external.notarySignerDatabase': 'notary_signer',
        'database.external.port': '5432',
        'redis.external.port': '6379',
        'persistence.persistentVolumeClaim.registry.size': '100Gi',
        'persistence.persistentVolumeClaim.redis.size': '5Gi',
        'persistence.persistentVolumeClaim.database.size': '5Gi',
        useRedisStorageClass: true,
        useDatabaseStorageClass: true,
        useRegistryStorageClass: true
      });

      if (Ember.get(this, 'enabled')) {
        var answers = Ember.get(this, 'app.answers') || {};
        var s3Keys = S3_KEYS.map(function (k) {
          return "imageChartStorage.s3.".concat(k);
        });
        var databaseExtenalKeys = DATABASE_EXTERNAL_KEYS.map(function (k) {
          return "database.external.".concat(k);
        });
        var redisExtenalKeys = RDISE_EXTENNAL_KEYS.map(function (k) {
          return "redis.external.".concat(k);
        });
        var answerKeys = Object.keys(ANSWER_TO_CONFIG) || [];
        var registryNodeSelector = {};
        var databaseNodeSelector = {};
        var redisNodeSelector = {};
        var clairNodeSelector = {};
        var notaryNodeSelector = {};
        Object.keys(answers).filter(function (key) {
          return key.startsWith(DATABASE_NODE_SELECTOR_PREFIX);
        }).map(function (k) {
          var value = answers[k] || '';
          var key = k.replace(DATABASE_NODE_SELECTOR_PREFIX, '').replace(/\\\./g, '.');
          databaseNodeSelector[key] = value;
        });
        Object.keys(answers).filter(function (key) {
          return key.startsWith(REDIS_NODE_SELECTOR_PREFIX);
        }).map(function (k) {
          var value = answers[k] || '';
          var key = k.replace(REDIS_NODE_SELECTOR_PREFIX, '').replace(/\\\./g, '.');
          redisNodeSelector[key] = value;
        });
        Object.keys(answers).filter(function (key) {
          return key.startsWith(REGISTRY_NODE_SELECTOR_PREFIX);
        }).map(function (k) {
          var value = answers[k] || '';
          var key = k.replace(REGISTRY_NODE_SELECTOR_PREFIX, '').replace(/\\\./g, '.');
          registryNodeSelector[key] = value;
        });
        Object.keys(answers).filter(function (key) {
          return key.startsWith(CLAIR_NODE_SELECTOR_PREFIX);
        }).map(function (k) {
          var value = answers[k] || '';
          var key = k.replace(CLAIR_NODE_SELECTOR_PREFIX, '').replace(/\\\./g, '.');
          clairNodeSelector[key] = value;
        });
        Object.keys(answers).filter(function (key) {
          return key.startsWith(NOTARY_NODE_SELECTOR_PREFIX);
        }).map(function (k) {
          var value = answers[k] || '';
          var key = k.replace(NOTARY_NODE_SELECTOR_PREFIX, '').replace(/\\\./g, '.');
          notaryNodeSelector[key] = value;
        });
        this.updateCpuMemoryPreRequest();
        Ember.setProperties(this, {
          registryNodeSelector: registryNodeSelector,
          databaseNodeSelector: databaseNodeSelector,
          redisNodeSelector: redisNodeSelector,
          clairNodeSelector: clairNodeSelector,
          notaryNodeSelector: notaryNodeSelector
        });
        var persistenceKeys = [];
        PERSISTENCE_COMPONENTS.map(function (component) {
          return PERSISTENCE_KEYS.map(function (k) {
            persistenceKeys = [].concat(_toConsumableArray(persistenceKeys), ["persistence.persistentVolumeClaim.".concat(component, ".").concat(k)]);
          });
        });
        var arr = [].concat(_toConsumableArray(s3Keys), _toConsumableArray(databaseExtenalKeys), _toConsumableArray(redisExtenalKeys), _toConsumableArray(persistenceKeys));
        Object.keys(answers).forEach(function (key) {
          if (key.startsWith(REGISTRY_NODE_SELECTOR_PREFIX) || key.startsWith(DATABASE_NODE_SELECTOR_PREFIX) || key.startsWith(REDIS_NODE_SELECTOR_PREFIX) || key.startsWith(CLAIR_NODE_SELECTOR_PREFIX) || key.startsWith(NOTARY_NODE_SELECTOR_PREFIX)) {
            return;
          }

          if (arr.includes(key)) {
            return Ember.set(_this7, key, answers[key]);
          }

          if (Object.keys(HIDDEN_KEYS).includes(key) || key === 'redis.external.coreDatabaseIndex') {
            return;
          }

          if (answerKeys.includes(key)) {
            var value;

            switch (key) {
              case DATABASE_REQUEST_CPU:
              case DATABASE_LIMIT_CPU:
              case REDIS_REQUEST_CPU:
              case REDIS_LIMIT_CPU:
              case REGISTRY_REQUEST_CPU:
              case REGISTRY_LIMIT_CPU:
              case CLAIR_REQUEST_CPU:
              case CLAIR_LIMIT_CPU:
              case NOTARY_REQUEST_CPU:
              case NOTARY_LIMIT_CPU:
              case SIGNER_REQUEST_CPU:
              case SIGNER_LIMIT_CPU:
                value = (0, _util.convertToMillis)(answers[key] || '0');
                break;

              case DATABASE_REQUEST_MEM:
              case DATABASE_LIMIT_MEM:
              case REDIS_REQUEST_MEM:
              case REDIS_LIMIT_MEM:
              case REGISTRY_REQUEST_MEM:
              case REGISTRY_LIMIT_MEM:
              case CLAIR_REQUEST_MEM:
              case CLAIR_LIMIT_MEM:
              case NOTARY_REQUEST_MEM:
              case NOTARY_LIMIT_MEM:
              case SIGNER_REQUEST_MEM:
              case SIGNER_LIMIT_MEM:
                value = (0, _parseUnit.parseSi)(answers[key] || '0', 1024) / 1048576;
                break;

              default:
                value = answers[key];
            }

            return Ember.set(_this7, "config.".concat(ANSWER_TO_CONFIG[key]), value);
          }

          customAnswers[key] = answers[key];
        });
      }

      Ember.set(this, 'customAnswers', customAnswers);
    },
    validate: function validate() {
      var _this8 = this;

      var errors = [];
      var intl = Ember.get(this, 'intl');
      var customAnswers = Ember.get(this, 'customAnswers');
      var exposeIngressHost = customAnswers[INGRESS_HOSTS_CORE] || window.location.host;

      if (/^[0-9.:]+$/.test(exposeIngressHost)) {
        errors.pushObject(intl.t('globalRegistryPage.config.exposeIngressHost.error', {
          hostName: exposeIngressHost
        }));
      }

      if (!Ember.get(this, 'config.password')) {
        errors.pushObject((0, _util.requiredError)('globalRegistryPage.setting.adminPassword.label'));
      }

      var secretKey = Ember.get(this, 'config.secretKey');

      if (secretKey && secretKey.length !== 16) {
        errors.pushObject(intl.t('globalRegistryPage.config.secretKey.error'));
      }

      if (Ember.get(this, 'config.storageType') === 'filesystem') {
        errors.pushObjects(this.validatePV('registry'));
      }

      errors.pushObjects(this.validateLimitAndRequest('registry'));

      if (Ember.get(this, 'config.databaseType') === INTERNAL) {
        errors.pushObjects(this.validatePV('database'));
        errors.pushObjects(this.validateLimitAndRequest('database'));
      }

      if (Ember.get(this, 'config.redisType') === INTERNAL) {
        errors.pushObjects(this.validatePV('redis'));
        errors.pushObjects(this.validateLimitAndRequest('redis'));
      }

      if (Ember.get(this, 'config.storageType') === 's3') {
        S3_KEYS.filter(function (k) {
          return k !== 'regionendpoint';
        }).map(function (k) {
          var key = "imageChartStorage.s3.".concat(k);

          if (!Ember.get(_this8, key)) {
            errors.pushObject((0, _util.requiredError)("globalRegistryPage.config.s3.".concat(k, ".label")));
          }
        });
      }

      if (Ember.get(this, 'config.databaseType') === 'external') {
        DATABASE_EXTERNAL_KEYS.map(function (k) {
          var key = "database.external.".concat(k);

          if (!Ember.get(_this8, key)) {
            errors.pushObject((0, _util.requiredError)("globalRegistryPage.config.database.external.".concat(k, ".label")));
          }
        });
      }

      if (Ember.get(this, 'config.redisType') === 'external') {
        RDISE_EXTENNAL_KEYS.filter(function (k) {
          return k !== 'password';
        }).map(function (k) {
          var key = "redis.external.".concat(k);

          if (!Ember.get(_this8, key)) {
            errors.pushObject((0, _util.requiredError)("globalRegistryPage.config.redis.external.".concat(k, ".label")));
          }
        });
      }

      if (Ember.get(this, 'config.notaryEnabled')) {
        errors.pushObjects(this.validateLimitAndRequest('notaryServer'));
        errors.pushObjects(this.validateLimitAndRequest('notarySigner'));
      }

      if (Ember.get(this, 'config.clairEnabled')) {
        errors.pushObjects(this.validateLimitAndRequest('clair'));
      }

      return errors.uniq();
    },
    willSavePersistence: function willSavePersistence(answers, component) {
      var _this9 = this;

      PERSISTENCE_KEYS.map(function (k) {
        var key = "persistence.persistentVolumeClaim.".concat(component, ".").concat(k);
        var useStorageClass = Ember.get(_this9, "use".concat((0, _util.ucFirst)(component), "StorageClass"));

        if (['storageClass', 'size'].includes(k) && useStorageClass) {
          answers[key] = Ember.get(_this9, key);
        }

        if (k === 'existingClaim' && !useStorageClass) {
          answers[key] = Ember.get(_this9, key);
        }
      });
    },
    validatePV: function validatePV(component) {
      var _this$storageClasses = this.storageClasses,
          storageClasses = _this$storageClasses === void 0 ? [] : _this$storageClasses,
          intl = this.intl;
      var errors = [];
      var defaultStorageClasses = storageClasses.filter(function (s) {
        return s.annotations && (s.annotations['storageclass.kubernetes.io/is-default-class'] === 'true' || s.annotations['storageclass.beta.kubernetes.io/is-default-class'] === 'true');
      });

      if (Ember.get(this, "use".concat((0, _util.ucFirst)(component), "StorageClass"))) {
        if (defaultStorageClasses.length === 0 && !Ember.get(this, "persistence.persistentVolumeClaim.".concat(component, ".storageClass"))) {
          var emptyError = intl.t('globalRegistryPage.config.storageClass.emptyError');
          errors.pushObject(emptyError);
        }

        if (!Ember.get(this, "persistence.persistentVolumeClaim.".concat(component, ".size"))) {
          errors.pushObject(intl.t('globalRegistryPage.config.storageClass.sizeRequired', {
            component: (0, _util.ucFirst)(component)
          }));
        }
      } else if (!Ember.get(this, "persistence.persistentVolumeClaim.".concat(component, ".existingClaim"))) {
        errors.pushObject((0, _util.requiredError)("clusterIstioPage.existingClaim.label", {
          component: (0, _util.ucFirst)(component)
        }));
      }

      return errors;
    },
    doneSaving: function doneSaving() {
      this.setGlobalRegistryEnabled('true');
      this.updateCpuMemoryPreRequest();
    },
    setGlobalRegistryEnabled: function setGlobalRegistryEnabled(value, successCB) {
      var _this10 = this;

      var globalStore = Ember.get(this, 'globalStore');
      var globalRegistryEnabled = globalStore.all('setting').findBy('id', 'global-registry-enabled');
      Ember.set(globalRegistryEnabled, 'value', value);
      globalRegistryEnabled.save().then(function () {
        if (successCB) {
          successCB();
        }
      }).catch(function (err) {
        Ember.get(_this10, 'growl').fromError(Ember.get(_this10, 'intl').t('globalRegistryPage.globalRegistryEnabled.error'), err);
      });
    },
    initWorkloads: function initWorkloads() {
      var _this11 = this;

      WORKLOADS.map(function (w) {
        Ember.defineProperty(_this11, "".concat(w, "SchedulableNodes"), Ember.computed("".concat(w, "NodeSelectors.[]"), 'cluster.nodes.@each.{allocatable,requested}', "config.".concat(w, "Enabled"), function () {
          return _this11.getSchedulableNodes(w);
        }));
        Ember.defineProperty(_this11, "insufficient".concat((0, _util.ucFirst)(w), "Cpu"), Ember.computed("".concat(w, "SchedulableNodes.@each.{allocatable,requested}"), "config.".concat(w, "RequestCpu"), 'cluster.nodes.@each.{allocatable,requested}', function () {
          return _this11.getComponentInsufficient(w, 'cpu');
        }));
        Ember.defineProperty(_this11, "insufficient".concat((0, _util.ucFirst)(w), "Memory"), Ember.computed("".concat(w, "SchedulableNodes.@each.{allocatable,requested}"), "config.".concat(w, "RequestMemory"), 'cluster.nodes.@each.{allocatable,requested}', function () {
          return _this11.getComponentInsufficient(w, 'memory');
        }));
        Ember.defineProperty(_this11, "".concat(w, "Warning"), Ember.computed("insufficient".concat((0, _util.ucFirst)(w), "Cpu"), "insufficient".concat((0, _util.ucFirst)(w), "Memory"), function () {
          return _this11.getComponentWarning(w);
        }));
      });
    },
    getEnalbedWorkloads: function getEnalbedWorkloads(answers) {
      var out = ['registry.registry'];

      if (answers['redis.type'] === 'internal') {
        out.push('redis.internal');
      }

      if (answers['database.type'] === 'internal') {
        out.push('database.internal');
      }

      if (answers['notary.enabled'] === 'true') {
        out = [].concat(_toConsumableArray(out), ['notary.server', 'notary.signer']);
      }

      if (answers['clair.enabled'] === 'true') {
        out.push('clair');
      }

      return out;
    }
  });

  _exports.default = _default;
});